<template>
    <div class="reload-div">
        <div>
            <el-button type="primary" @click="getAll">重新加载</el-button>
        </div>
        <div class="left-data">
            {{ next.id }}
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            index: 0,
            total: 0,
            next: {
                id: '',
            },
            baseUrl: '/bbs/answer/reload',
        }
    },
    mounted() {

    },
    methods: {
        getAll() {
            this.$bbs.post(this.baseUrl, {id: this.next.id}).then(data => {
                if (data.data.answer_id) {
                    this.next.id = data.data.answer_id;
                    this.getAll();
                }else{
                    this.$message.success('刷新完成');
                }
            }).catch(() => {

            })
        }
    }
}
</script>
<style scoped lang="less">
.reload-div {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .left-data{
        font-size: 28px;
        font-weight: bold;
        margin-top: 30px;
    }
}
</style>
